import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap, mapTo, catchError } from "rxjs/operators";
import { TokenService } from '../token.service';
import { Profile } from 'src/app/models/Profile';
import { stringify } from '@angular/compiler/src/util';
import { RoleTypes } from 'src/app/models/Role';

export interface RefreshTokenRequestModel {
  token: string;
}

export interface LoginSuccessModel {
  access_token: string;
  userId: string;
  email: string;
  expires_in: number;
  refresh_token: string;
  roles: string[];
  token_type: string;
}

export interface DecodedToken {
  exp: Date;
  iat: Date;
  jti: string;
  profile: Profile;
  role: string[];
  sub: string;
  userId: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public loginSuccess: EventEmitter<LoginSuccessModel> = new EventEmitter<LoginSuccessModel>();
  public logoutSuccess: EventEmitter<any> = new EventEmitter<any>();
  public profileChanged: EventEmitter<any> = new EventEmitter<any>();

  private userStorageKey = "user";
  private profileStoraggeKey = "_profile";
  private jwtTokenKey = "JWT_TOKEN";
  private refreshTokenKey = "REFRESH_TOKEN";
  private loggedUser: string = '';

  constructor(private http: HttpClient, private tokenService: TokenService) { }


  public login(email: string, password: string) {
    return this.http.post<LoginSuccessModel>(`${environment.serverPath}/api/auth/token`, { email, password })
      .pipe(
        tap(response => {
          const decoded = this.tokenService.decodeJwtPayload(response.access_token);
          this.doLoginUser(`${decoded.profile.firstname} ${decoded.profile.lastname}`, response);
          Profile.fixImage(decoded.profile);
          this.saveLocalProfile(decoded.profile);
        }),
        mapTo(true),
        catchError(error => {
          return of(false);
        })
      );
  }

  public refreshToken(): Observable<boolean> {
    const model: RefreshTokenRequestModel = {
      token: this.getRefreshToken()
    };

    return this.http.post<LoginSuccessModel>(`${environment.serverPath}/api/auth/refresh-token`, model)
      .pipe(
        tap(response => {
          const decoded = this.tokenService.decodeJwtPayload(response.access_token);
          this.doLoginUser(`${decoded.profile.firstname} ${decoded.profile.lastname}`, response);
          Profile.fixImage(decoded.profile);
          this.saveLocalProfile(decoded.profile);
        }),
        mapTo(true),
        catchError(error => {
          return of(false);
        })
      );
  }

  public register(firstname: string, lastname: string, email: string, role: string, password: string) {
    return this.http.post<LoginSuccessModel>(`${environment.serverPath}/api/auth/register`, { firstname, lastname, email, role, password });
  }

  public activateAccount(model: any) {
    return this.http.post<any>(`${environment.serverPath}/api/auth/activate`, model);
  }

  public forgetPassword(email: string) {
    return this.http.post<boolean>(`${environment.serverPath}/api/auth/forget-password`, { email });
  }

  public resetPassword(model: any) {
    return this.http.post<boolean>(`${environment.serverPath}/api/auth/reset-password`, model);
  }

  public checkEmailExists(email: string) {
    return this.http.post(`${environment.serverPath}/api/auth/check-mail`, { email });
  }

  public getUserProfile() {
    return this.http.get<Profile>(`${environment.serverPath}/api/profile`);
  }

  public isAuthenticated() {
    return this.http.get<boolean>(`${environment.serverPath}/api/authorize`);
  }

  public saveLocalProfile(profile: Profile) {
    localStorage.setItem(this.profileStoraggeKey, JSON.stringify(profile));
    this.profileChanged.emit(profile);
  }

  public getLocalProfile() {
    const p = localStorage.getItem(this.profileStoraggeKey);

    if (!p) return null;

    return JSON.parse(p) as Profile;
  }

  public getAuthorizationToken(): string | null {
    return localStorage.getItem(this.jwtTokenKey);
  }

  public logout(): Observable<boolean> {
    this.doLogoutUser();
    return of(true);
  }

  public getUserRoles(): string[] {
    const access_token = localStorage.getItem(this.jwtTokenKey);
    const decoded = this.tokenService.decodeJwtPayload(access_token);
    return decoded.role;
  }

  public HasRole(role: RoleTypes) {
    const access_token = localStorage.getItem(this.jwtTokenKey);
    const decoded = this.tokenService.decodeJwtPayload(access_token);
    return decoded.role.find((x: string) => x === role) != undefined;
  }


  private getRefreshToken(): string {
    return (localStorage.getItem(this.refreshTokenKey) as string);
  }

  private doLoginUser(username: string, loginResponse: LoginSuccessModel) {
    this.loggedUser = username;
    this.storeTokens(loginResponse);
    this.loginSuccess.emit(loginResponse);
  }

  private doLogoutUser() {
    let activeToken;
    this.loggedUser = '';
    this.removeTokens();

    localStorage.removeItem("gymSettings");
    localStorage.removeItem("language");
    localStorage.removeItem("_profile");

    if (activeToken) {
      this.logoutSuccess.emit(activeToken);
    }
  }

  private removeTokens() {
    localStorage.removeItem(this.jwtTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem(this.userStorageKey);
  }


  private storeTokens(loginResponse: LoginSuccessModel) {
    localStorage.setItem(this.jwtTokenKey, loginResponse.access_token);
    localStorage.setItem(this.refreshTokenKey, loginResponse.refresh_token);
    localStorage.setItem(this.userStorageKey, JSON.stringify(loginResponse));
  }

}
