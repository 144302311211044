import { NgModule } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TimeDurationPipe } from "./pipe/TimeDuration";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/translations/");
}

@NgModule({
  declarations: [
    TimeDurationPipe
  ],
  exports: [
    TranslateModule,
    TimeDurationPipe
  ]
})
export class AppSharedModule { }
