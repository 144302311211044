import { Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class MessageBroadcasterService {
    private messageHub: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    broadcastMessage(message: any) {
        console.log(message);
        this.messageHub.emit(message);
    }

    get onMessage() {
        return this.messageHub;
    }
}