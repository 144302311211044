import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService, ConfirmationService } from "primeng/api";

@Injectable()
export class ToastService {
    private generalToastCloseTime = 3000;
    constructor(
        private translationService: TranslateService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService
    ) { }

    public successMessage(message: string, data: any = {}) {
        this.messageService.add({
            severity: "success",
            summary: this.translationService.instant("alerts.successTitle"),
            detail: this.translationService.instant(message, data),
            life: this.generalToastCloseTime
        });
    }

    public infoMessage(message: string, data: any = {}) {
        this.messageService.add({
            severity: "info",
            summary: this.translationService.instant("alerts.infoTitle"),
            detail: this.translationService.instant(message, data),
            life: this.generalToastCloseTime
        });
    }

    public errorMessage(message: string, data: any = {}) {
        this.messageService.add({
            severity: "error",
            summary: this.translationService.instant("alerts.errorTitle"),
            detail: this.translationService.instant(message, data),
            life: this.generalToastCloseTime
        });
    }

    public invalidFormError() {
        this.messageService.add({
            severity: "error",
            summary: this.translationService.instant("alerts.errorTitle"),
            detail: this.translationService.instant("alerts.INVALID_FORM_CORRECT_THEM"),
            life: this.generalToastCloseTime
        });
    }

    public generalErrorMessage() {
        this.messageService.add({
            severity: "error",
            summary: this.translationService.instant("alerts.successTitle"),
            detail: this.translationService.instant("alerts.GENERAL_ERROR"),
            life: this.generalToastCloseTime
        });
    }

    public confirmDelete(message: string = "alerts.confirmDeleteMessage", callback: () => void) {
        this.confirmationService.confirm({
            message: this.translationService.instant(message),
            acceptButtonStyleClass: "ui-button-success",
            rejectButtonStyleClass: "ui-button-danger",
            accept: () => {
                callback();
            }
        });
    }

    public confirmWithMessage(message: string, callback: () => void, data: any = {}) {
        console.log(data);
        this.confirmationService.confirm({
            message: this.translationService.instant(message, data),
            acceptButtonStyleClass: "ui-button-success",
            rejectButtonStyleClass: "ui-button-danger",
            accept: () => {
                callback();
            }
        });
    }
}