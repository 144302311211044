import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Helpers } from './services/app.helpers';
import { AuthenticationService } from './services/authentication/authentication.service';
import { LoggerService } from './services/logger.service';
import { MessageBroadcasterService } from './services/message.service';
import * as moment from "moment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angular-swear';

  constructor(
    private translateService: TranslateService,
    private messageService: MessageBroadcasterService,
    private authService: AuthenticationService,
    private logger: LoggerService,
    private router: Router,
    private helpers: Helpers) {

    this.translateService.addLangs(['en', 'gr']);
    this.translateService.setDefaultLang(environment.defaultLang);
    moment.locale(this.helpers.getLocale());

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(this.helpers.getLocale());
    });
  }
}
