import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeDuration'
})
export class TimeDurationPipe implements PipeTransform {

  transform(endDate: Date | undefined, startDate: Date | undefined): string {
    if (!endDate || !startDate)
      return "00:00:00";

    const diff = new Date(endDate).getTime() - new Date(startDate).getTime();
    return moment(diff).utc().format("HH:mm:ss");
  }
}
