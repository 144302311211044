import { environment } from "src/environments/environment";

export class Profile {
    id: number | undefined;
    userId: number | undefined;
    firstname: string | undefined;
    lastname: string | undefined;
    image: string | undefined;
    gender: string | undefined;
    dateOfBirth: Date | undefined;
    profileCover: string | undefined;
    weight: number | undefined;
    height: number | undefined;
    createdAt: Date | undefined;
    updatedAt: Date | undefined;

    public static fixImage(profile: Profile): Profile {
        if (!profile.image) {
            profile.image = "assets/images/profile.jpg";
        } else {
            if (profile.image.indexOf("http") === -1) {
                profile.image = environment.serverPath + profile.image;
            }
        }

        return profile;
    }
};