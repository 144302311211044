import { FormGroup, FormArray, AbstractControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { ToastService } from "./authentication/toast.service";

@Injectable()
export class Helpers {
    constructor(
        private toastService: ToastService,
        private translateService: TranslateService,
        private httpClient: HttpClient) { }


    public static updateObjectValues(obj1: any, obj2: any) {
        (Object as any).keys(obj2).forEach((key: string) => {
            obj1[key] = obj2[key];
        });
    }

    public static updateFormGroup(formGroup: AbstractControl, values: any) {
        (Object as any).keys(formGroup.value).forEach((key: string) => {
            if (values[key]) {
                if (formGroup.get(key)?.value instanceof Date)
                    formGroup.get(key)?.setValue(new Date(values[key]));
                else
                    formGroup.get(key)?.setValue(values[key]);
            }
        });
    }

    public static markFormGroupTouched(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach((control: FormGroup) => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    public static markFormGroupDirty(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach((control: FormGroup) => {
            control.markAsDirty();

            if (control.controls) {
                this.markFormGroupDirty(control);
            }
        });
    }

    public static markFormGroupPristine(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach((control: FormGroup) => {
            control.markAsPristine();

            if (control.controls) {
                this.markFormGroupPristine(control);
            }
        });
    }

    public static markFormGroupUntouched(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach((control: FormGroup) => {
            control.markAsUntouched();

            if (control.controls) {
                this.markFormGroupUntouched(control);
            }
        });
    }

    public static searchDataArray(searchStr: string, arr: Array<any>): Array<any> {
        const filtered = arr.filter(x => {
            for (let prop in x) {
                if (x[prop] && x[prop].toLowerCase().indexOf(searchStr.toLowerCase()) > -1) return true;
            }
            return false;
        });

        return filtered;
    }

    public static jsonToFormEncoded(data: any) {
        const str = [];
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                str.push(encodeURIComponent(key) + "=" + encodeURIComponent(data[key]));
            }
        }
        return str.join("&");
    }

    public static getQueryStringParameter(paramName: string) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.has(paramName) ? urlParams.get(paramName) : undefined;
    }

    public static capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    public static navigateByUrl(relativeUrl: string) {
        const ref = document.createElement("a");
        ref.href = relativeUrl;
        ref.click();
    }

    uploadImage(file: any) {
        const formData = new FormData();
        formData.append("file", file);
        return this.httpClient.post(`${environment.serverPath}/api/general/upload-image`, formData);
    }

    deleteImage(file: string) {
        return this.httpClient.delete(`${environment.serverPath}/api/general/upload-image?image=${file}`);
    }



    public responseHasErrors(response: any, showToast: boolean = false) {
        if (!response) return false;

        let hasErrors = false;

        if (response.status && response.status === 500) {
            hasErrors = true;
            if (showToast) {
                let message = this.translateService.instant(`alerts.${response.statusText}`);
                if (response.error && response.error.error) {
                    if (response.error.error.exception.find((d: any) => d.class === "Doctrine\\DBAL\\Exception\\ForeignKeyConstraintViolationException")) {
                        const error = response.error.error.exception.find((d: any) => d.class === "Doctrine\\DBAL\\Exception\\ForeignKeyConstraintViolationException");

                        if (error.message.indexOf("DELETE FROM")) {
                            message = this.translateService.instant("alerts.CannotDeleteIntegrityConstraint");
                        }
                    }
                }
                this.toastService.errorMessage(message);
            }
        } else if (response.status && response.status === 400 && response.statusText === "VALIDATION_ERROR"
            && response.message && response.message instanceof Array) {
            hasErrors = true;
            if (showToast) {
                if (response.message[0].message === "NO_CONSENT") {
                    this.toastService.errorMessage(this.translateService.instant(`alerts.${response.message[0].message}`));
                }
            }
        }

        return hasErrors;
    }

    isValid(name: string, form: FormGroup): boolean {
        let control = null;
        if (form) {
            control = form.get(name);
        }
        if (control) {
            return control.valid || !control.touched;
        } else {
            return true;
        }
    }

    removeGreekAccents(str: string) {
        const text = str.replace(/Ά|Α|ά/g, "α")
            .replace(/Έ|Ε|έ/g, "ε")
            .replace(/Ή|Η|ή/g, "η")
            .replace(/Ί|Ϊ|Ι|ί|ΐ|ϊ/g, "ι")
            .replace(/Ό|Ο|ό/g, "ο")
            .replace(/Ύ|Ϋ|Υ|ύ|ΰ|ϋ/g, "υ")
            .replace(/Ώ|Ω|ώ/g, "ω")
            .replace(/Σ|ς/g, "σ");
        return text;
    }

    getLocale() {
        let locale = this.translateService.currentLang;
        if (locale === "gr") {
            locale = "el";
        }

        return locale;
    }




}