import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastService } from './services/authentication/toast.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MessageBroadcasterService } from './services/message.service';
import { LoggerService } from './services/logger.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Helpers } from './services/app.helpers';
import { FormService } from './services/Form.service';
import { TokenService } from './services/token.service';
import { ForgetPasswordComponent } from './pages/auth/forget-password/forget-password.component';
import { ServerRequestInterceptorInterceptor } from './services/server-request-interceptor.interceptor';
import { AppSharedModule, HttpLoaderFactory } from './app.shared.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastModule } from "primeng/toast";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { ActivateAccountComponent } from './pages/auth/activate-account/activate-account.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    ToastModule,
    FormsModule,
    AppSharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ServerRequestInterceptorInterceptor, multi: true },
    LoggerService,
    MessageBroadcasterService,
    MessageService,
    ToastService,
    ConfirmationService,
    Helpers,
    FormService,
    TokenService,
    AuthenticationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
