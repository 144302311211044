import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ServerRequestInterceptorInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.url.startsWith(`${environment.serverPath}/api/auth`)) {
      return next.handle(request);
    }

    const authToken = this.authenticationService.getAuthorizationToken();

    if (authToken == null)
      throw Error("user is not logged in");

    const authReq = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${authToken}`)
    });

    return next.handle(authReq);

  }
}
